import React, {useContext, useEffect, useState} from 'react'
import {AddButton, Description} from './Specific.styled'
import {User} from '../../../context/userContext';
import {useNavigate, useParams} from 'react-router';
import NotFound from '../../Errors/NotFound/NotFound';
import {Button} from 'react-bootstrap';
import Comments from './Comments/Comments';
import GoBack from '../../Utils/GoBack/GoBack';
import QuickRate from './QuickRate/QuickRate';
import PDFViewFile from '../Specific/PDFViewFile/PDFViewFile'
import useAdmin from '../../../hooks/useAdmin';
import moment from 'moment'
import Files from "./Files/Files";

const Specfic = () => {
    const routeID = useParams();
    const navigator = useNavigate();

    let [user] = useContext(User);
    let [error, setError] = useState(false)
    let [report, setReport] = useState(null);
    let [indicators, setIndicators] = useState(null);
    let [task, setTask] = useState(null);
    let [comments, setComments] = useState([]);

    let [showAddFileModal, setShowAddFileModal] = useState(false);
    let [showAddCommentModal, setShowAddCommentModal] = useState(false);
    let isTeacher = useAdmin();

    const approveComment = async (commentId) => {
        const proposedComment = comments.find((comment) => comment.id === commentId);

        if (!proposedComment) {
            console.error('Proposed comment not found');
            return;
        }

        try {
            const formData = new FormData();
            formData.append('text', proposedComment.text);  // Treść komentarza
            formData.append('report', proposedComment.report);  // Powiązany raport

            const response = await fetch(`${process.env.REACT_APP_HOST}/api/comments/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                    // Nie dodawaj 'Content-Type', gdyż `FormData` automatycznie ustawi odpowiednie nagłówki
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to approve comment');
            }

            const data = await response.json();
            console.log('Comment added:', data);

            fetchDetails();  // Zaktualizuje komentarze i raport
            // Zaktualizuj komentarze lub wykonaj dodatkowe działania, jeśli potrzebne
        } catch (err) {
            console.error(err);
            setError(true);
        }
    };


    const fetchDetails = async () => {
        await fetch(`${process.env.REACT_APP_HOST}/api/report_extend/${routeID.id}/`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${user.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(res => {
                if (res.status != 200) {
                    throw new Error('Not found');
                }
                return res.json();
            })
            .then(data => {
                console.log("fetchDetails", data);
                setReport(data);
                setComments(data.comments); // Zaktualizuj stan komentarzy bezpośrednio
            })
            .catch(err => {
                setError(true);
                console.log("fetchDetails err", err);
            })
    }

    const getNextRepId = async () => {
        await fetch(`${process.env.REACT_APP_HOST}/api/reports/${report.id}/?next`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${user.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json()).then(data => {
                console.log("getNextRepId");
                console.log(data);
                let rep = data.id;
                if (rep) {
                    navigator(`/sprawozdania/${rep}`)
                    navigator(0)
                } else navigator(`/zajecia/`)

            }).catch((error) => {
                console.error('Error:', error);
            });

    }

    const fetchProposedComments = async () => {
        console.log("fetchProposedComments");

        if (!report || !report.id) {
            console.log("Report is null or undefined");
            return; // Wyjście, jeśli report nie istnieje
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_HOST}/api/proposed_comments/?report_id=${routeID.id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Not found');
            }

            const data = await response.json();
            setComments(data); // Assuming you store fetched comments in the state

            console.log("comments", data);

        } catch (err) {
            console.log("fetchProposedComments err ", err);
            setError(true);
        }
    };


    const saveReport = async () => {
        await fetch(`${process.env.REACT_APP_HOST}/api/reports/${report.id}/`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${user.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                sent: true
            }),
        })
            .then(res => {
                if (res.status != 200) {
                    throw new Error('Not found');
                }
                return res.json();
            })
            .then(data => {
                console.log(data);
                fetchDetails();
            })
            .catch(err => {
                setError(true);
                console.log(err);
            })
    }


    const handleRepUpdate = async (rep) => {
        const url = `${process.env.REACT_APP_HOST}/api/reports/${rep.id}/`;
        fetch(url, {
            method: 'PATCH',
            headers: {
                'Authorization': 'Bearer ' + user?.token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                sent: !rep.sent
            })
        }).then(response => response.json()).then(data => {
            console.log("GOOD", data);

            fetchDetails();
        }).catch((error) => {
            console.error('Error:', error);
        });
    }


    const formatDeadline = (deadline) => {
        if (!deadline)
            return '-'
        return moment(deadline).format('DD.MM.YYYY');
    }

    useEffect(() => {
        if (user.logged) {
            fetchDetails();
        }
    }, [user.logged]);

    useEffect(() => {
        if (report) {
            fetchProposedComments();
        }
    }, [report]);


    const handleAddReport = () => {
        setShowAddFileModal(true);
    }

    const handleAddComment = () => {
        setShowAddCommentModal(true);
    }

    const getFloatGrade = (grade) => {
        if (!grade)
            return 'Nie oceniono';
        return parseFloat(grade, 10).toFixed(1);
    }

    const updateGrade = (grade) => {
        report.grade = grade;
        fetchDetails();
    }

    const getStudentName = (student) => {
        if (!student)
            return '';
        return `${student?.first_name} ${student?.last_name} (${student?.username?.includes('@') ? student.username.split('@')[0] : student.username})`.trim();
    }

    return (
        <>
            {(() => {
                if (error) {
                    return <NotFound/>
                }
                if (!report) {
                    return <></>
                }
                return (
                    <>
                        <GoBack/>

                        <div className="container mt-5">
                            <div className="content">
                                <h4><i className="bi bi-file-earmark-text"></i> {report?.task?.name ?? ''}

                                    {isTeacher && report?.sent &&
                                        <i className="bi bi-lock text-danger pointer m-2 p-2"
                                           onClick={() => handleRepUpdate(report)} style={{fontSize: "16px"}}
                                           data-toggle="tooltip" data-placement="bottom"
                                           title="Odblokuj do poprawy"></i>
                                    }
                                    {isTeacher && !report?.sent &&
                                        <i className="bi bi-unlock text-success pointer m-2 p-2"
                                           onClick={() => handleRepUpdate(report)} style={{fontSize: "16px"}}
                                           data-toggle="tooltip" data-placement="bottom" title="Zablokuj"> </i>
                                    }
                                </h4>

                                <h6>{report?.lecture?.name ?? ''}</h6>
                                <Description>{task?.description ?? ''}</Description>


                                <div className="row mt-4">
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="form-label"><i className="bi bi-person-fill"></i> Student:</label>
                                            <input type="text" className="form-control"
                                                   value={getStudentName(report?.student)}
                                                   disabled/>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="form-label"><i
                                                className="bi bi-person-badge-fill"></i> Prowadzący:</label>
                                            <input type="text" className="form-control"
                                                   value={report?.lecture?.lecturer ?? ''}
                                                   disabled/>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="form-label"><i className="bi bi-calendar-fill"></i> Termin
                                                oddania:</label>
                                            <input type="text"
                                                   className="form-control"
                                                   value={formatDeadline(report?.task?.deadline)}
                                                   disabled/>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="form-label"><i
                                                className="bi bi-bar-chart-fill"></i> Wskaźnik użycia Chatu GPT:</label>
                                            <input type="text" className="form-control"
                                                   value={report.content_check?.chat_gpt_usage ?? ''} disabled/>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="form-label"><i
                                                className="bi bi-bar-chart-fill"></i> Wskaźnik Plagiatu obrazów:</label>
                                            <input type="text" className="form-control" value="wyłączony" disabled/>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="form-label"><i
                                                className="bi bi-bar-chart-fill"></i> Wskaźnik plagiatu tekstu:</label>
                                            <input type="text" className="form-control" value="wyłączony" disabled/>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="col-lg-10 col-md-10 col-sm-8 col-12">
                                        <h5>Komentarze prowadzącego:</h5>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-4 col-12 text-end">
                                        {isTeacher &&
                                            <button type="button" onClick={handleAddComment}
                                                    className="btn btn-outline-primary d-inline-flex align-items-center my-1"
                                                    data-bs-toggle="dropdown" aria-expanded="false"><i
                                                className="bi bi-plus-lg icon_margin-right text-primary">

                                            </i>Własny
                                            </button>}
                                    </div>

                                    <div className="col-12">
                                        <Comments showAddCommentModal={showAddCommentModal}
                                                  setShowAddCommentModal={setShowAddCommentModal}
                                                  comments={report?.comments} handleOnUpload={fetchDetails}/>
                                    </div>
                                </div>

                                {isTeacher && <div className="row mb-4">
                                    <div className="col-12">
                                        <h5>Proponowane Komentarze:</h5>
                                        <ul className="list-group">
                                            {comments.length > 0 ? (
                                                comments.map((comment) => (
                                                    <li key={comment.id}
                                                        className="list-group-item d-flex justify-content-between align-items-center">
                                                        {comment.text}
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-primary btn-sm"
                                                            onClick={() => approveComment(comment.id)}
                                                        >
                                                            Zatwierdź
                                                        </button>
                                                    </li>
                                                ))
                                            ) : (
                                                <li className="list-group-item">Brak komentarzy do wyświetlenia.</li>
                                            )}
                                        </ul>
                                    </div>
                                </div>}


                                <div className="row mb-4">
                                    <div className="col-lg-10 col-md-10 col-sm-8 col-12">
                                        <h5>PDF Sprawozdania:</h5>
                                    </div>
                                    <div className="col-12">
                                        {report?.files[0] &&
                                            <PDFViewFile files={report?.files}></PDFViewFile>}
                                        {!report?.files[0] && "brak."}
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="col-lg-10 col-md-10 col-sm-8 col-12">
                                        <h5>Przesłane pliki:</h5>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-4 col-12 text-end">
                                        {!report.sent &&
                                            <AddButton style={{float: "right"}} variant="outline-primary"
                                                       onClick={handleAddReport}>
                                                <i className="bi bi-plus-lg icon_margin-right text-primary">

                                                </i>Dodaj plik
                                            </AddButton>}
                                    </div>

                                    <div className="col-12">

                                        <Files showAddFileModal={showAddFileModal}
                                               setShowAddFileModal={setShowAddFileModal}
                                               files={report?.files} handleOnUpload={fetchDetails}/>
                                    </div>
                                </div>

                                <div className="row">

                                    <div className="col-12">

                                        {!report.sent && <AddButton style={{float: "right"}} variant="outline-primary"
                                                                    onClick={saveReport}><i
                                            className="bi bi-upload icon_margin-right text-primary">

                                        </i>Wyślij sprawozdanie</AddButton>
                                        }
                                        {report.sent &&
                                            <AddButton style={{float: "right"}} variant="outline-primary disabled"><i
                                                className="bi bi-hand-thumbs-up icon_margin-right text-primary"></i>Wysłano</AddButton>
                                        }
                                    </div>
                                </div>


                                {isTeacher &&
                                    <>
                                        <div className="row mb-4">
                                            <div className="col-lg-10 col-md-10 col-sm-8 col-12">
                                                <h5>Ocena:</h5>
                                            </div>
                                            <div className="col-12">
                                                <QuickRate report={report} updateGrade={updateGrade}></QuickRate>

                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <Button style={{float: "right"}} variant="outline-primary m-1"
                                                        onClick={getNextRepId}><i
                                                    className="bi bi-arrow-right-square icon_margin-right text-primary"></i>Następny
                                                    do oceny</Button>
                                            </div>
                                        </div>
                                    </>}
                            </div>
                        </div>
                    </>
                )
            })()}
        </>
    )
}

export default Specfic